import styled from "styled-components"

export const FeaturesSection = styled.section`
  text-align: center;
`

export const CardGrid = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 2rem;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-flow: column;
    margin: 0;
  }
`
export const CardWrapper = styled.div`
  width: 20rem;
  min-width: 300px;
  border-radius: 50px;
  padding: 0 3rem;
  margin: 1rem;
  box-shadow: 0px 0px 25px -2px rgba(0, 255, 255, 0.45);
  margin-top: 8rem;

  @media (max-width: ${props => props.theme.screen.sm}) {
    min-width: auto;
    width: 90%;
    margin-top: 40%;
  }
`

export const ImageWrapper = styled.div`
  position: absolute;
  top: -28%;
  left: 0;
  right: 0;
`

export const ContentWrapper = styled.div`
  padding-top: 40%;
  padding-bottom: 5%;
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding-top: 45%;
  }
`

export const Card = styled.div`
  position: relative;
`

export const Title = styled.h2`
  text-align: center;
  margin: 2rem 0 0;
  user-select: none;
`
export const SubTitle = styled.h4``
export const List = styled.ul``

export const ListItem = styled.li`
  margin: 1rem 0;
  text-align: left;
  color: #09beeb;
  span {
    color: #000;
  }
`
