import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import {
  CardGrid,
  CardWrapper,
  Card,
  Title,
  List,
  ImageWrapper,
  ContentWrapper,
  FeaturesSection,
  SubTitle,
  ListItem,
} from "./style"

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      mockupFirst: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "home_features_mockup_1" }
      ) {
        childImageSharp {
          fluid(maxWidth: 254, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mockupSecond: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "home_features_mockup_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 254, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mockupThird: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "home_features_mockup_3" }
      ) {
        childImageSharp {
          fluid(maxWidth: 254, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <FeaturesSection id="features">
      <Title>Features of BIOX SmartHospital</Title>
      <CardGrid>
        <CardWrapper>
          <Card>
            <ImageWrapper>
              <Img fluid={data.mockupSecond.childImageSharp.fluid} />
            </ImageWrapper>
            <ContentWrapper>
              <SubTitle>Real-time sync</SubTitle>
              <List>
                <ListItem>
                  <span>Instant access to continuous vital signs</span>
                </ListItem>
                <ListItem>
                  <span>Secure, remote, online access from anywhere</span>
                </ListItem>
                <ListItem>
                  <span>
                    Real-time data about the availability of beds for
                    hospitalization
                  </span>
                </ListItem>
                <ListItem>
                  <span>Customizable parameters and notification system</span>
                </ListItem>
              </List>
            </ContentWrapper>
          </Card>
        </CardWrapper>
        <CardWrapper>
          <Card>
            <ImageWrapper>
              <Img fluid={data.mockupFirst.childImageSharp.fluid} />
            </ImageWrapper>
            <ContentWrapper>
              <SubTitle>Cloud-based</SubTitle>
              <List>
                <ListItem>
                  <span>Integrated patient data</span>
                </ListItem>
                <ListItem>
                  <span>Improved resource utilization and management</span>
                </ListItem>
                <ListItem>
                  <span>Data security</span>
                </ListItem>
                <ListItem>
                  <span>
                    Compatible with existing patient management platforms
                  </span>
                </ListItem>
                <ListItem>
                  <span>
                    Backup data source with access to vital signs when server or
                    EMR is down
                  </span>
                </ListItem>
              </List>
            </ContentWrapper>
          </Card>
        </CardWrapper>
        <CardWrapper>
          <Card>
            <ImageWrapper>
              <Img fluid={data.mockupThird.childImageSharp.fluid} />
            </ImageWrapper>
            <ContentWrapper>
              <SubTitle>Automated Process</SubTitle>
              <List>
                <ListItem>
                  <span>
                    Allows clinicians to customize interface and application to
                    meet their facilities needs
                  </span>
                </ListItem>
                <ListItem>
                  <span>
                    Interfaces with industry-standard systems like HL7 to
                    deliver precise results
                  </span>
                </ListItem>
                <ListItem>
                  <span>
                    Aids clinicians to decrease errors by offering cross
                    directional patient identification
                  </span>
                </ListItem>
              </List>
            </ContentWrapper>
          </Card>
        </CardWrapper>
      </CardGrid>
    </FeaturesSection>
  )
}

export default Features
