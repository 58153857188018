import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import CtaButton from "@common/CtaButton"
import { Content } from "./style"

const Cta = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(
        sourceInstanceName: { eq: "background" }
        name: { eq: "home_cta_background" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id="cta">
      <BackgroundImage
        className="cta"
        Tag="div"
        fluid={data.background.childImageSharp.fluid}
        style={{
          width: "100%",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Content>
          <h1>One click away, start using SmartHospital</h1>
          <a
            href="https://service.bioxcorp.net/app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CtaButton text={"Sign in"} />
          </a>
        </Content>
      </BackgroundImage>
    </section>
  )
}

export default Cta
