import styled from "styled-components"

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 5%;

  h1 {
    margin-bottom: 2rem;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    text-align: center;
  }
`
