import styled from "styled-components"

import { Container } from "@components/global"

export const ProductsSection = styled.section`
  position: relative;
  margin-top: -5%;

  .backgroundArt {
    @media (max-width: ${props => props.theme.screen.sm}) {
      display: none;
    }
  }
`

export const ThirdContainer = styled(Container)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: stretch;
  padding: 2rem 0;
  padding-top: 0;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-flow: column;
    align-items: center;
  }
`

export const CardWrapper = styled.div`
  width: 25%;
  min-width: 350px;
  background: rgb(9, 191, 236);
  background: linear-gradient(
    0deg,
    rgba(9, 191, 236, 1) 0%,
    rgba(11, 174, 231, 1) 100%
  );
  border-radius: 10px;
  padding: 3px;
  box-shadow: 0px 0px 25px -2px rgba(0, 255, 255, 0.45);

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin: 1rem 0;
  }
`

export const Card = styled.div`
  height: 100%;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 40px;
  padding: 3rem 1rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.h5`
  ${props => props.theme.font_size.regular};
  user-select: none;
`

export const Text = styled.p`
  ${props => props.theme.font_size.xsmall};
  text-align: center;
  margin-bottom: 2rem;
`

export const SvgBackground = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
`
