import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { StyledContainer, Title, Text } from "./style"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(
        sourceInstanceName: { eq: "background" }
        name: { eq: "home_about_background" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id="about_us">
      <BackgroundImage
        className="cta"
        Tag="div"
        fluid={data.background.childImageSharp.fluid}
        style={{
          width: "100%",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <StyledContainer>
          <Title>Our vision for the future of healthcare!</Title>
          <Text>
            We, at BIOX, strive to offer innovative healthcare solutions that
            improve the overall care of patients. Our vision is to create smart
            hospitals, whereby enhanced communication, superior monitoring, and
            increased efficiency leads to exceptional global healthcare. We
            strive to develop and improve cutting edge technology that is driven
            by the goal of improved patient clinical outcome.
          </Text>
          <Text>
            Our current developments include a communications device that will
            replace in-house data servers – saving cost, time, space, and
            maintenance - facilitating increased hospital productivity. Our
            future products, are being designed after extensive research and
            analysis, that will provide a number of AI enhanced tools in order
            to optimize patient monitoring, health decay analysis and condition
            prediction/ diagnosis. Our vision for a smart hospital includes
            plans to design a cloud-based EMR which integrated with our device
            ecosystem provides fully automated EMR and hospital organization
            tools that will vastly improve patient care. We aim to integrate our
            knowledge and experiences in medicine and technology to conceive
            products that will allow patients and physicians to evolve and
            reform healthcare management.
          </Text>
          <Text>
            We have envisioned a brighter future for the healthcare system where
            every patient will be equipped to manage their health more
            effectively in coordination with their physician in an attempt to
            recognize and prevent pathology and their complications, as well as
            than focus on treating them.
          </Text>
        </StyledContainer>
      </BackgroundImage>
    </section>
  )
}

export default About
