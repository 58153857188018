import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import CtaButton from "@common/CtaButton"

import {
  BreakSection,
  FirstContainer,
  SecondContainer,
  Content,
  Title,
  SubTitle,
  ImageWrapper,
  ImageWrapper1,
  Reference,
  HorizontalLine,
  Text,
} from "./style"

const Software = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(
        sourceInstanceName: { eq: "background" }
        name: { eq: "home_software_background" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mockupFirst: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "home_software_mockup_1" }
      ) {
        childImageSharp {
          fluid(maxWidth: 670) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mockupSecond: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "home_software_mockup_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id="software">
      <FirstContainer>
        <div>
          <Title>What's BIOX SmartHospital eco-system?</Title>
          <SubTitle>
            A cloud-based remote patient monitoring solution and hospital
            organization tool
          </SubTitle>
          <Text>
            Evidence based research suggests that using continuous vital sign
            monitoring (cVSM) demonstrated a statistically significant 27%
            decrease in the complication rate, and a clinically significant
            decrease in transfers to an intensive care unit and
            failure-to-rescue (FTR) events rate [1] and if vital signs were more
            accurately and frequently measured, and acted on promptly and
            appropriately hospital care would be safer, better and cheaper [2].
            With that in mind we designed Biox SmartHospital ecosystem. Biox
            SmartHospital software package offers a cloud-based remote patient
            monitoring solution that allows physicians to access their patients
            data including vital signs, reports, and medical history at any time
            from any device.
          </Text>
          <HorizontalLine />
          <Reference>
            <p>References:</p>
            <p>
              1. Verrillo, Sue Carol DNP, RN, CRRN; Cvach, Maria DNP, RN, FAAN;
              Hudson, Krysia Warren DNP, RN, BC; Winters, Bradford D. PhD, MD,
              FCCM. Using Continuous Vital Sign Monitoring to Detect Early
              Deterioration in Adult Postoperative Inpatients. Journal of
              Nursing Care Quality: April/June 2019 - Volume 34 - Issue 2 - p
              107-113
            </p>
            <p>
              2. Kellett, John et al. Make vital signs great again – A call for
              action. European Journal of Internal Medicine, Volume 45, 13 - 19
            </p>
          </Reference>
        </div>
        <ImageWrapper1>
          <Img fluid={data.mockupFirst.childImageSharp.fluid} />
        </ImageWrapper1>
      </FirstContainer>
      <BreakSection>
        <BackgroundImage
          className="software"
          Tag="div"
          fluid={data.background.childImageSharp.fluid}
          style={{
            width: "100%",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <SecondContainer>
            <Content>
              <SubTitle>Fully Automated Hospital</SubTitle>
              <p>
                Biox’s SmartHospital ecosystem was designed to organize and
                provide not only vital sign data but also real time information
                about the availability of devices and inpatient beds so that no
                time is wasted in admitting patients and starting treatment.
                With the provided information, hospitals will know where each
                patient and device is at all times.
              </p>
              <Link to="/software/">
                <CtaButton text="Explore" />
              </Link>
            </Content>
            <ImageWrapper>
              <Img
                fluid={data.mockupSecond.childImageSharp.fluid}
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  width: "100%",
                }}
              />
            </ImageWrapper>
          </SecondContainer>
        </BackgroundImage>
      </BreakSection>
    </section>
  )
}

export default Software
