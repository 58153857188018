import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import {
  TeamSection,
  ImageWrapper,
  TextWrapper,
  Text,
  SubText,
  Title,
  Card,
} from "./style"

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      team: file(sourceInstanceName: { eq: "art" }, name: { eq: "home_team" }) {
        childImageSharp {
          fluid(maxWidth: 1250) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <TeamSection id="team">
      <ImageWrapper>
        <Img
          fluid={data.team.childImageSharp.fluid}
          style={{ height: "100%", width: "100%" }}
        />
      </ImageWrapper>
      <TextWrapper>
        <Card>
          <Title>When Time is Vital</Title>
          <Text>
            The Biox SmartHospital software package aims to change the interface
            between clinician and patient. Vital signs are the most important
            part of the patient encounter. The more access we have to a
            patient’s vital signs, the more we are able to understand their
            condition. A chronicle of their vital signs communicates
            progressively and exponentially, letting clinicians recognize
            whether their patients’ conditions are stable, or fluctuating
            acutely.
          </Text>
          <Text>
            The Biox SmartHospital software package provides continuous,
            real-time, remote and secure access to patients’ vital signs. It
            prevents the need to continually visit patients only to check their
            vitals, thereby improving clinical efficiency by saving time,
            improving care, and decreasing the risk for disease transmission.
            Furthermore, the software notifies clinicians of a change in
            vitals—allowing for timelier detection and earlier time to
            intervention – ultimately, allowing for improved patient outcome.
          </Text>
          <SubText>Jassin Jouria M.D.</SubText>
          <SubText>Emergency Medicine Physician</SubText>
        </Card>
      </TextWrapper>
    </TeamSection>
  )
}

export default Team
