import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

import CtaButton from "@common/CtaButton"
import { ReactComponent as PulsLine } from "@static/pulse_line_1.svg"

import {
  Card,
  CardWrapper,
  ProductsSection,
  ThirdContainer,
  Title,
  Text,
  SvgBackground,
} from "./style"

const Products = () => {
  const data = useStaticQuery(graphql`
    query {
      mockupSoftware: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "home_products_mockup_1" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mockupDevices: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "home_products_mockup_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <ProductsSection id="devices" style={{ overflowX: "hidden" }}>
      <PulsLine className="backgroundArt" />
      <SvgBackground
        viewBox="0 0 1920 400"
        aria-hidden="true"
        className="backgroundArt"
      >
        <path
          d="M 0 200 Q 480 400 960 200 Q 1440 0 1920 200 L 1920 400 L 0 400 L 0 200"
          fill="#85d7f3"
        />
        <path
          d="M 0 240 Q 480 400 960 200 Q 1440 0 1920 240 L 1920 400 L 0 400 L 0 200"
          fill="#0baee7"
        />
      </SvgBackground>
      <ThirdContainer>
        <CardWrapper>
          <Card>
            <Img
              fluid={data.mockupSoftware.childImageSharp.fluid}
              style={{ width: "100%" }}
            />
            <Title>Software</Title>
            <Text>
              Modern and customizable interface for doctors focused on workflow
              and ease of access to information
            </Text>
            <Link to="/software/">
              <CtaButton text="Learn more" small />
            </Link>
          </Card>
        </CardWrapper>
        <CardWrapper>
          <Card>
            <Img
              fluid={data.mockupDevices.childImageSharp.fluid}
              style={{ width: "100%" }}
            />
            <Title>Devices</Title>
            <Text>
              Our devices are designed for continuous vitals sign tracking and
              communication to provide easy 24 hour access to live patients data
            </Text>
            <Link
              to="/devices-modal/"
              state={{
                modal: true,
                noScroll: true,
              }}
            >
              <CtaButton text="Learn more" small />
            </Link>
          </Card>
        </CardWrapper>
      </ThirdContainer>
    </ProductsSection>
  )
}

export default Products
