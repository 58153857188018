import styled from "styled-components"

export const TeamSection = styled.section`
  background-color: #0baee7;
  padding-top: 10vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
    flex-flow: column;
    background-color: #fff;
    padding-top: 0;
  }
`

export const ImageWrapper = styled.div`
  background: #fff;
`

export const TextWrapper = styled.div`
  background: #fff;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h5`
  ${props => props.theme.font_size.regular};
  user-select: none;
  color: #007099;
  margin: 0 0 1rem;
`

export const Text = styled.p`
  ${props => props.theme.font_size.xsmall};
  text-align: left;
  margin: 1rem 0;
  color: #007099;
`

export const SubText = styled.p`
  ${props => props.theme.font_size.xsmall};
  font-weight: 700;
  width: 100%;
  text-align: right;
  color: #007099;

  @media (max-width: ${props => props.theme.screen.sm}) {
    text-align: center;
  }
`

export const Card = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  // align-items: flex-start;
  // padding: 1rem;
  // border: 1px solid #00ffff;
  // margin: 1rem;
  // margin-right: 3rem;

  box-shadow: 0px 0px 25px -2px rgba(0, 255, 255, 0.45);
  margin: 3rem;
  border-radius: 30px;
  padding: 1rem 2rem;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin: 1rem;
    align-items: center;
    border: none;
  }
`
