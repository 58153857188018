import styled from "styled-components"

import { Container } from "@components/global"

export const FirstContainer = styled(Container)`
  display: grid;
  grid-template-columns: 55% 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0px;
  margin-bottom: 5%;
  padding-top: 74px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const SecondContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  padding: 5rem 0;
  padding-top: 2rem;
  color: #fff;

  p {
    ${props => props.theme.font_size.small};
    margin-bottom: 2rem;
    color: #fff;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 2rem 0;
  }
`

export const Content = styled.div`
  padding-top: 3rem;

  p {
    @media (max-width: ${props => props.theme.screen.sm}) {
      margin-right: 0;
      text-align: center;
    }
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 1rem;
    padding-top: 3rem;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  margin-top: 3rem;
`

export const ImageWrapper1 = styled.div`
  align-self: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    height: 50vh;
    width: 100%;
    margin-top: 1rem;
  }
`

export const BreakSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 0.5fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  height: fit-content;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    margin-bottom: 64px;
  }
`

export const Title = styled.h3`
  font-weight: 300;
  padding-bottom: 1rem;
  padding-right: 0.5rem;
  margin-bottom: 2rem;
  border-bottom: 3px solid #09bfec;
  width: fit-content;
  user-select: none;
`

export const SubTitle = styled.h3`
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-right: 10%;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-right: 0;
    text-align: center;
  }
`

export const Text = styled.p`
  ${props => props.theme.font_size.small};
  margin-right: 5%;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-right: 0;
  }
`

export const Reference = styled.div`
  p {
    ${props => props.theme.font_size.xsmall};
    color: #000;
    margin-top: 0.5rem;
  }
`

export const HorizontalLine = styled.div`
  height: 1px;
  margin: 1rem 0;
  width: 70%;
  content: " ";
  background-color: #09bfec;
`
