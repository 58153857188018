import styled from "styled-components"

import { Container } from "@components/global"

export const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  padding: 10vh 0;
  margin-top: 5vh;
  color: #fff;
  p {
    ${props => props.theme.font_size.small};
    color: #fff;
    margin: 1rem 0;
  }
`

export const Title = styled.h2`
  ${props => props.theme.font_size.large};
  text-align: center;
  margin-bottom: 2rem;
  user-select: none;
`

export const Text = styled.p`
  ${props => props.theme.font_size.xsmall};
  text-align: left;
  padding: 0 2rem;
`
