import React from "react"

import Layout from "@common/Layout"
import Header from "@sections/homePage/Header"
import Software from "@sections/homePage/Software"
import Products from "@sections/homePage/Products"
import Team from "@sections/homePage/Team"
import Cta from "@sections/homePage/Cta"
import Features from "@sections/homePage/Features"
import About from "@sections/homePage/About"
import Contact from "@common/Contact"
import Footer from "@common/Footer"

const NAV_ITEMS = ["Software", "Devices", "About Us", "Contact"]

const IndexPage = () => (
  <Layout navItems={NAV_ITEMS} color="rgba(10, 180, 233, 0.9)">
    <Header />
    <Software />
    <Products />
    <Team />
    <Cta />
    <Features />
    <About />
    <Contact />
    <Footer />
  </Layout>
)

export default IndexPage
